body, html {
  height: 100%;
  margin: 0;
  font-size: 16px;
  font-family: 'Cormorant', serif;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 2px;
  color: #666;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6, p, span, div {
  font-family: 'Cormorant', serif;
}

h2 {
  font-size: 24px;
}

h6 {
  font-size: .7rem;
}

a:hover {
  text-decoration: none !important;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  text-align: left;
}

.Menu {
  position: relative;
}

.About p {
  font-family: 'ABeeZee', sans-serif;
}

.About .social {
  text-align: center;

}

.About .social img {
  margin: 0 16px;
  width: 36px;
  height: auto;
}

.Welcome {
  position: relative;
}


header {
  background: transparent;
  width: 100%;
  height: 54px;
  z-index: 100;
  position: absolute;
}

header ul {
  text-align: right;
}

header ul li {
  display: inline-block;
  padding: 12px;
  -moz-transition: opacity .25s ease-in-out;
  -webkit-transition: opacity .25s ease-in-out;
}
header ul li:hover {
  opacity: .5;
}

header ul li img {
  width: 30px;
  height: auto;
}

header .menu {
  padding: 12px;
  position: fixed;
  top: 0;
  cursor: pointer;
  -moz-transition: opacity .25s ease-in-out;
  -webkit-transition: opacity .25s ease-in-out;
}

header .menu:hover img {
  opacity: .5;
}

header .menu img {
  display: inline-block;
  width: 32px;
  height: auto;
  margin: 9px;
}

header .menu ul {
  float: right;
  opacity: 0;
  font-size: 16px;
  font-weight: bold;
  background: rgba(146, 89, 252, .8);
  border-radius: 25px;
  transition: opacity .5s ease-in-out;
  -moz-transition: opacity .5s ease-in-out;
  -webkit-transition: opacity .5s ease-in-out;
}

header .menu:hover ul {
  opacity: 1;
}

header .menu ul a {
  color: #ffffff
}

.background-image {
  height: 500px;
  position: relative;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.background-image.blank {
  min-height: 5%;
}

.pimg {
  background-image: url('./img/background.png');
  min-height: 100%;
}

.pimg2 {
  background-image: url('./img/background2.png');
  min-height: 100%;
  max-height: 208px;
}

.welcome-text {
  width: 60%;
  margin: 36px auto;
  text-align: center;
}

.welcome-text  p {
  font-size: 18px;
  font-weight: 600;
}

.dropdown-menu {
  max-height: 300px;
  height: auto;
  overflow-x: hidden;
}

.dropdown-menu::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.dropdown-menu::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(108, 117, 125, .8);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

button.sized {
  width: 136px;
}

section {
  padding: 42px 60px;
  text-align: center;
}

.section-purple {
  background-color: #e7d9cb;
  /*background-color: #c0a0ff;*/
  color: #000000;
}

.section-purple p {
  font-size: 22px;
  font-weight: 500;
}

.section-purple h1 span, .section-purple p span  {
  /*color: white;
  color: #9259fc;*/
  color: black;
  font-weight: 900;
  /*font-size: 25px;*/
}

.section-blank .video iframe {
  box-shadow: 0px 10px 5px #888, 0px -10px 5px #888;

}

.section-dark {
  background-color: #282e34;
  color: #ddd;
}

.section-dark .close-text {
  padding: 0 36px;
}

.section-dark h1 span {
  /*color: #e7d9cb;*/
  color: #9259fc;
}

.section-dark p {
  text-align: left;
}

.section-white {
  height: 360px;
}

.section-white .single-column {
  max-width: 420px;
  margin: 0 auto;
}

.section-white li {
  padding: 6px 0;
  list-style-type: none;
  text-align: left;
  font-size: 18px;
}

.section-white li img {
  display: inline-block;
  width: 24px;
  height: auto;
  margin: 0 12px 0 0;
  vertical-align: top;
}

.error {
  color: #ffffff;
  font-style: italic;
}

footer {
  width: 100%;
  padding: 16px 8px;
  background: #cccccc;
  font-size: 12px;
  text-align: center;
  vertical-align: middle;
  font-family: 'Lato', sans-serif;
}

footer p {
  width: 80%;
  margin: 0 auto;
  font-family: revert;
}

footer a {
  color: #ffffff;
}

/* random stylings */
.script {
  font-family: 'Great Vibes', cursive;
  line-height: 0;
}

/* bootstrap override */
.list-group-item {
  text-align: left;
  color: #000000;
}

.section-purple table th, .section-purple table td {
  padding: .5rem;
}

.section-purple table img {
  width: 36px;
  height: auto;
  text-align: center;
  position: relative;
  right: -25%;
}

.section-purple table a:link {
  color: white;
  background-color: transparent;
  text-decoration: underline;
  font-style: italic;
}

.section-purple table a:visited  {
  color: white;
  background-color: transparent;
  text-decoration: underline;
  font-style: italic;
}

.section-purple table a:hover  {
  color: #9259fc;
  background-color: transparent;
  text-decoration: none;
}

input[type=checkbox] {
  width: 18px;
  height: 18px;
  margin: 0;
  vertical-align: middle;
}

.hidden { display: none; }

@media screen and (min-width: 320px) and (max-width: 820px) {
  section {
    padding: 50px 20px;
    word-wrap: break-word;
  }
}

@media screen and (min-width: 701px) and (max-width: 1032px) {
  .section-white {
    height: 440px;
  }
}


@media screen and (min-width: 320px) and (max-width: 700px) {
  header ul li {
    padding: 3px 8px;
  }
  header .menu ul li {
    display: block;
    text-align: center;
  }
  .section-white {
    height: 660px;
  }
  .section-white .col {
    flex-basis: auto;
  }
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  section {
    padding: 50px 24px;
  }
  .section-white {
    height: 700px;
  }
  .section-white .col {
    padding: 0;
  }
  .section-white li {
    font-size: 14px;
  }
  .section-dark .col-sm {
    padding: 24px 0;
  }
  .welcome-text {
    font-size: .75rem;
  }
}

@media screen and (min-width: 1270px) {
  .pimg2 {
    max-height: 260px;
  }
  .welcome-text {
    margin: 56px auto;
  }
}
/**
Old Welcome Image
@media screen and (min-width: 1021px) and (max-width: 1220px) {
  .welcome-text {
    width: 40%;
    top: 50%;
  }
}

@media screen and (min-width: 1021px) and (max-width: 1220px) {
  .welcome-text {
    width: 50%;
    top: 50%;
  }
}

@media screen and (min-width: 621px) and (max-width: 1020px) {
  .welcome-text {
    width: 60%;
    top: 44%;
  }
}

@media screen and (min-width: 421px) and (max-width: 620px) {
  .welcome-text {
    width: 70%;
    top: 44%;
  }
}

@media screen and (min-width: 320px) and (max-width: 420px) {
  .welcome-text {
    top: 33%;
  }
}
**/
